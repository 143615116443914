import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import MyConstant from './config/MyConstant';
import SpeakUp from './speakup/SpeakUp';
import SpeakUpTest from './speakup/SpeakUpTest';
import SpeakUpStory from './speakup/SpeakUpStory';
import SpeakUpUnsubscribe from './speakup/SpeakUpUnsubscribe';
import PrivacyPolicy from './speakup/PrivacyPolicy';
import AppIssues from './speakup/feedback/AppIssues';
import ForgotPassword from './speakup/feedback/ForgotPassword';
import EditIssues from './speakup/feedback/AppIssues/EditIssues';
import SpeakUpHome from './speakup/SpeakUpHome';
import AboutUs from './speakup/AboutUs';
import JoinCall from './speakup/JoinCall';
import ManageLogin from './speakup/ManageLogin';
import OverView from './speakup/OverView';
import WelcomeBanner from './component/WelcomeBanner';

const speakupProto = require('./protobuf/speakup/speakup_grpc_web_pb');
const umedmeProto = require('./protobuf/umedme/umedme_grpc_web_pb');
let grpcClient = new speakupProto.ZiFetchServiceClient(MyConstant.keyList.speakupGrpcURL, {}, {});
let umedmeGrpcClient = new umedmeProto.ZiFetchServiceClient(MyConstant.keyList.grpcURL, {}, {});


const history = require("history").createBrowserHistory;

class RouterApp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <div>
        <Router>
          <Switch>

            <Route exact path={MyConstant.keyList.projectUrl + "/login"}>
              <ManageLogin grpcClient={grpcClient} history={history} />
            </Route>
            <Route exact path={MyConstant.keyList.projectUrl + "/"}>
              <div className='banner-v3'>
                <WelcomeBanner page={"home"} />
                <SpeakUpHome grpcClient={grpcClient} history={history} />
              </div>
            </Route>
            <Route exact path={MyConstant.keyList.projectUrl + "/join-call"}>
              <div className='banner-v3'>
                <WelcomeBanner page={"home"} />
                <JoinCall grpcClient={grpcClient} history={history} />
              </div>
            </Route>
            <Route exact path={MyConstant.keyList.projectUrl + "/about-us"}>
              <div className='banner-v3'>
                <WelcomeBanner page={"home"} />
                <AboutUs grpcClient={grpcClient} history={history} />
              </div>
            </Route>
            <Route exact path={MyConstant.keyList.projectUrl + "/share-story"}>
              <div className='banner-v3'>
                <WelcomeBanner page={"home"} />
                <SpeakUpStory grpcClient={grpcClient} history={history} />
              </div>
            </Route>

            <Route exact path={MyConstant.keyList.projectUrl + "/verify-subscription/:id"}>
              <SpeakUp grpcClient={grpcClient} history={history} />
            </Route>
            <Route exact path={MyConstant.keyList.projectUrl + "/unsubscribe/:id/:email"}>
              <SpeakUpUnsubscribe grpcClient={grpcClient} history={history} />
            </Route>
            <Route exact path={MyConstant.keyList.projectUrl + "/privacy-policy"}>
              <PrivacyPolicy grpcClient={grpcClient} history={history} />
            </Route>
            <Route exact path={MyConstant.keyList.projectUrl + "/app-feedback"}>
              <AppIssues umedmeGrpcClient={umedmeGrpcClient} grpcClient={grpcClient} history={history} />
            </Route>
            <Route exact path={MyConstant.keyList.projectUrl + "/edit-feedback/:editId"}>
              <EditIssues umedmeGrpcClient={umedmeGrpcClient} grpcClient={grpcClient} history={history} />
            </Route>
            <Route exact path={MyConstant.keyList.projectUrl + "/forgot-password"}>
              <ForgotPassword umedmeGrpcClient={umedmeGrpcClient} grpcClient={grpcClient} history={history} />
            </Route>
            <Route exact path={MyConstant.keyList.projectUrl + "/over-view"}>
              <div className='banner-v3'>
                <WelcomeBanner page={"home"} />
                <OverView umedmeGrpcClient={umedmeGrpcClient} grpcClient={grpcClient} history={history} />
              </div>
            </Route>

          </Switch>
        </Router>
      </div>
    )
  }
}


export default RouterApp;
