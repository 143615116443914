import React from 'react';
import { Link } from 'react-router-dom';
import MyConstant from '../../config/MyConstant';
import logo from '../../images/speakupv2/speakup-logo.png';

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileMenu: false,
            menuOpen: []
        }
    }

    menuOpenSetup(value) {
        let { menuOpen } = this.state;

        if (menuOpen.includes(value)) {
            let index = menuOpen.indexOf(value);
            menuOpen.splice(index, 1)
        } else {
            menuOpen.push(value)
        }

        this.setState({
            menuOpen
        })
    }
    render() {
        let { mobileMenu, menuOpen } = this.state;
        return <React.Fragment>
            <div className='header'>
                <div className='main-logo'>
                    <Link to={MyConstant.keyList.projectUrl}>
                        <img alt="SpeakUp" className='header-logo' src={logo} />
                    </Link>
                </div>
                <div className='nav-link'>
                    <div className='dropdown'>
                        <span>About Us <i className="fa fa-caret-down pl-2"></i></span>
                        <div className="dropdown-content">
                            <Link className="dropdown-sub-txt" to={MyConstant.keyList.projectUrl + "/about-us"}>
                                Who We Are
                            </Link>
                            <Link className="dropdown-sub-txt " to={MyConstant.keyList.projectUrl + "/over-view"}>
                                Overview
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='join-call-link'>
                    <Link to={MyConstant.keyList.projectUrl + "/join-call"}>
                        Join The Call
                    </Link>
                </div>
                <div className='mobile-link' onClick={() => {
                    this.setState({
                        mobileMenu: true
                    })
                }}>
                    <i className="fa fa-bars"></i>
                </div>
            </div>
            {
                mobileMenu && <div className='mobile-menu'>
                    <div className='header d-flex'>
                        <div className='main-logo'>
                            <Link to={MyConstant.keyList.projectUrl + "/"}>
                                <img alt="SpeakUp" className='header-logo' src={logo} />
                            </Link>
                        </div>
                        <div className='menu-close' onClick={() => {
                            this.setState({
                                mobileMenu: false
                            })
                        }}>
                            <i className='fa fa-times' />
                        </div>
                    </div>
                    <div className='links'>
                        <div>
                            <div onClick={() => {
                                this.menuOpenSetup("about")
                            }}>About Us <i className={`fa fa-caret-${menuOpen.includes("about") ? "down" : "right"} pl-2`}></i></div>
                            {
                                menuOpen.includes("about") && <div className='pl-5'>
                                    <Link to={MyConstant.keyList.projectUrl + "/about-us"}>
                                        Who We Are
                                    </Link>
                                </div>
                            }

                        </div>
                    </div>
                    <div className='bottom-links'>
                        <div className='join-call-link-mobile'>
                            <Link to={MyConstant.keyList.projectUrl + "/join-call"}>
                                Join The Call
                            </Link>
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>;
    }
}
