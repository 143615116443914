import React from 'react';
import Footer from './Footer';
import Header from './Header';
import Header2 from './Header2';
import { v4 as uuidv4 } from 'uuid';
import { ZiFetchRequest } from '../protobuf/umedme/umedme_grpc_web_pb';
import MyConstant from '../config/MyConstant';
import { Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha"
import MyConfig from '../config/MyConfig';
import { ToastContainer, toast } from 'react-toastify';
import { userTrack } from '../config/MyFunctions';


export default class SpeakUpStory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            country: [
                "Africa",
                "Asia or Oceania",
                "Central and South America",
                "Europe",
                "Middle East",
                "North America",
            ],
            selectedCountry: "Africa",
            question1: "",
            question2: "",
            question3: "",
            question4: "",
            question5: "",
            question6: "",
            question7: "",
            thankForSubmit: false,
            message: "",
            email: "",
            errors: {},
            loader: false
        }
        this.captchaRef = React.createRef();
    }

    componentDidMount() {
        userTrack("SpeakUp Story", "Landing");
    }

    validateEmail(email) {
        return email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    }

    submit() {
        let { email, question1, question2, question3, question4, question5, question6, question7, selectedCountry } = this.state;
        let uid = uuidv4();
        const token = this.captchaRef.current.getValue();
        let errors = {};
        if (email.trim() === "") {
            errors["email"] = "Email is required."
        } else if (!this.validateEmail(email)) {
            errors["email"] = "Email is invalid."
        }

        this.setState({
            errors
        })

        if (Object.keys(errors).length === 0 && token) {
            this.setState({
                loader: true
            })

            let postJson = {
                json: JSON.stringify({
                    question1, question2, question3, question4, question5, question6, question7, selectedCountry
                }),
                email,
                templateVersion: "1",
                ipAddress: "",
                id: uid
            }

            const request = new ZiFetchRequest();
            request.setDomain('speakUp');
            request.setEvent('submission');
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === 'Invalid Request') {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    userTrack("SpeakUp Story", "Submit");

                    let responseData = JSON.parse(response.getMessage());
                    response = responseData.response
                    this.captchaRef.current.reset();

                    this.setState({
                        loader: false,
                        selectedCountry: "Africa",
                        question1: "",
                        question2: "",
                        question3: "",
                        question4: "",
                        question5: "",
                        question6: "",
                        question7: "",
                        thankForSubmit: true,
                        email: "",
                        message: "Thanks for Submission Story."
                    })
                }
            });
        } else {
            toast.error('Please fill all the required fields.', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    render() {
        let { loader, errors, email, country, selectedCountry, question1, question2, question3, question4, question5, question6, question7, thankForSubmit, message } = this.state;
        return <div className={`speakup-page speakup-v2`} >
            <Header2 /> 
            <ToastContainer />
            <div className='body'>
                <div className='container pt-5'>
                    <div className='text-right'>
                        <Link to={MyConstant.keyList.projectUrl + "/join-call"} className='back-join-call' style={{ cursor: "pointer" }} ><i className="fa fa-arrow-left" aria-hidden="true"></i> Join The Call</Link>
                    </div>
                    <div className='speak-up-submission-heading py-5'>
                        Speak Up Submission
                    </div>
                    <div className='speak-up-content pb-5'>
                        #SpeakUpCoalition is an initiative to increase awareness of bias, inequities, and harassment within the field of medicine. By raising awareness and acknowledging that the problem exists, we advance culture change. This is NOT a smear campaign. We wish to highlight the stories of those who do not feel heard and want to amplify their voice, while also sharing positive experiences in which our colleagues are doing and saying the right thing. We foster active bystander advocacy - crucial for those in positions where they feel powerless to speak up or take action. Thank you for being a part of this. Together, we speak up.
                    </div>
                    <div className='speak-up-question py-2'>
                        <label>Submit how you yourself have overcome adversity or handled a difficult situation! Submit an incident where someone stood up for you and advocated for you!</label>
                        <textarea className={errors["question1"] ? "form-control is-invalid " : 'form-control'} value={question1} onChange={(e) => {
                            this.setState({
                                question1: e.target.value
                            })
                        }} />
                        {
                            errors["question1"] && <div className="invalid-feedback">
                                {errors["question1"]}
                            </div>
                        }
                    </div>
                    <div className='speak-up-question py-2'>
                        <label>Submit your story of how SpeakUpCoalition has had an impact on you, your residency, your training, or your workplace!</label>
                        <textarea className={errors["question2"] ? "form-control is-invalid " : 'form-control'} value={question2} onChange={(e) => {
                            this.setState({
                                question2: e.target.value
                            })
                        }} />
                        {
                            errors["question2"] && <div className="invalid-feedback">
                                {errors["question2"]}
                            </div>
                        }
                    </div>
                    <div className='speak-up-question py-2'>
                        <label>ONLY if you would like to disclose your identity to SpeakUpCoalition, please include your information below (Name, Email, Phone, Social Media Handles).</label>
                        <textarea className={errors["question3"] ? "form-control is-invalid " : 'form-control'} value={question3} onChange={(e) => {
                            this.setState({
                                question3: e.target.value
                            })
                        }} />
                        {
                            errors["question3"] && <div className="invalid-feedback">
                                {errors["question3"]}
                            </div>
                        }
                    </div>
                    <div className='speak-up-question py-2'>
                        <label>REPORTING EXPERIENCE: Please tell us your experience when you tried to report an abusive behavior. Was the situation resolved? Did you face retaliation? Help us understand the process you experienced.</label>
                        <textarea className={errors["question4"] ? "form-control is-invalid " : 'form-control'} value={question4} onChange={(e) => {
                            this.setState({
                                question4: e.target.value
                            })
                        }} />
                        {
                            errors["question4"] && <div className="invalid-feedback">
                                {errors["question4"]}
                            </div>
                        }
                    </div>
                    <div className='speak-up-question py-2'>
                        <label>AWARENESS: Please tell us your story that you'd like to submit for use on the SpeakUpCoalition social platforms. All stories will remain anonymous, and any identifying details of personal identity or institution will be removed. By submitting this story, you consent to its anonymous publication by SpeakUpCoalition. Your submission may be shortened to fit the platform (i.e. twitter, facebook, instagram), while maintaining the integrity of the message.</label>
                        <textarea className={errors["question5"] ? "form-control is-invalid " : 'form-control'} value={question5} onChange={(e) => {
                            this.setState({
                                question5: e.target.value
                            })
                        }} />
                        {
                            errors["question5"] && <div className="invalid-feedback">
                                {errors["question5"]}
                            </div>
                        }
                    </div>
                    <div className='speak-up-question py-2'>
                        <label>ADVOCACY: Please share instances in which you have witnessed or have directly been a part of active bystander advocacy when there has been bias, harassment or abuse.</label>
                        <textarea className={errors["question6"] ? "form-control is-invalid " : 'form-control'} value={question6} onChange={(e) => {
                            this.setState({
                                question6: e.target.value
                            })
                        }} />
                        {
                            errors["question6"] && <div className="invalid-feedback">
                                {errors["question6"]}
                            </div>
                        }
                    </div>
                    <div className='speak-up-question py-2'>
                        <label>ACTION: Share your ideas for creating a better work environment so that we can use your recommendations to implement change.</label>
                        <textarea className={errors["question7"] ? "form-control is-invalid " : 'form-control'} value={question7} onChange={(e) => {
                            this.setState({
                                question7: e.target.value
                            })
                        }} />
                        {
                            errors["question7"] && <div className="invalid-feedback">
                                {errors["question7"]}
                            </div>
                        }
                    </div>
                    <div className='speak-up-question py-2'>
                        <label>Region You Are From:</label>
                        {
                            country.map((item, index) => {
                                return <div className="form-check" key={index}>
                                    <input name="country" className="form-check-input" type="radio" value={item} checked={item === selectedCountry} onChange={(e) => {
                                        this.setState({
                                            selectedCountry: e.target.value
                                        })
                                    }} />
                                    <label className="form-check-label" >
                                        &nbsp; {item}
                                    </label>
                                </div>
                            })
                        }
                    </div>
                    <div className='speak-up-question py-2'>
                        <div className='row'>
                            <div className='col-sm-4'>
                                <label>Email:</label>
                                <input type="text" className={errors["email"] ? "form-control is-invalid " : 'form-control'} value={email} onChange={(e) => {
                                    this.setState({
                                        email: e.target.value
                                    })
                                }} />
                                {
                                    errors["email"] && <div className="invalid-feedback">
                                        {errors["email"]}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className='py-5'>
                        <ReCAPTCHA
                            ref={this.captchaRef}
                            sitekey={MyConfig.siteKey}
                        />
                    </div>
                    <div className='pt-2 pb-5'>
                        <button className={`btn btn-story-submit ${loader ? "disabled" : ""}`} onClick={() => this.submit()}>
                            {
                                loader && <i className="fa fa-spinner fa-spin"></i>
                            }
                            &nbsp;
                            Submit</button>
                    </div>
                    {
                        thankForSubmit &&
                        <div className='signing-thanks'>
                            {message}
                        </div>
                    }
                </div>
            </div>
            <Footer />
        </div>;
    }
}
