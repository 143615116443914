import React from 'react';
import Footer from './Footer';
import Header from './Header2';
import p_1 from '../images/speakup/p_1.png';
import p_2 from '../images/speakup/p_2.png';
import p_3 from '../images/speakup/p_3.png';
import p_4 from '../images/speakup/p_4.png';
import MyConstant from '../config/MyConstant';
import { ZiFetchRequest } from '../protobuf/umedme/umedme_grpc_web_pb';
import { userTrack } from '../config/MyFunctions';
import Screen1 from '../images/speakup/Screen1_New.png';
import Screen2 from '../images/speakup/Screen2_New.png';
import Screen3 from '../images/speakup/Screen3_New.png';
import Screen4 from '../images/speakup/Screen4_New.png';
import applestore from '../images/speakup/apple.png'
import playstore from '../images/speakup/playstorea.png'

export default class JoinCall extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            country: [
                "Afghanistan",
                "Albania",
                "Algeria",
                "Andorra",
                "Angola",
                "Antigua & Barbuda",
                "Argentina",
                "Armenia",
                "Australia",
                "Austria",
                "Azerbaijan",
                "Bahamas",
                "Bahrain",
                "Bangladesh",
                "Barbados",
                "Belarus",
                "Belgium",
                "Belize",
                "Benin",
                "Bhutan",
                "Bolivia",
                "Bosnia & Herzegovina",
                "Botswana",
                "Brazil",
                "Brunei",
                "Bulgaria",
                "Burkina Faso",
                "Burundi",
                "Cambodia",
                "Cameroon",
                "Canada",
                "Cape Verde",
                "Central African Republic",
                "Chad",
                "Chile",
                "China",
                "Colombia",
                "Comoros",
                "Congo",
                "Congo Democratic Republic",
                "Cook Islands",
                "Costa Rica",
                "Cote d'Ivoire",
                "Croatia",
                "Cuba",
                "Cyprus",
                "Czech Republic",
                "Denmark",
                "Djibouti",
                "Dominica",
                "Dominican Republic",
                "Ecuador",
                "East Timor",
                "Egypt",
                "El Salvador",
                "Equatorial Guinea",
                "Eritrea",
                "Estonia",
                "Eswatini",
                "Ethiopia",
                "Fiji",
                "Finland",
                "France",
                "Gabon",
                "Gambia",
                "Georgia",
                "Germany",
                "Ghana",
                "Greece",
                "Grenada",
                "Guatemala",
                "Guinea",
                "Guinea-Bissau",
                "Guyana",
                "Haiti",
                "Holy See",
                "Honduras",
                "Hungary",
                "Iceland",
                "India",
                "Indonesia",
                "Iran",
                "Iraq",
                "Ireland",
                "Israel",
                "Italy",
                "Jamaica",
                "Japan",
                "Jordan",
                "Kazakhstan",
                "Kenya",
                "Kiribati",
                "Korea North",
                "Korea South",
                "Kuwait",
                "Kyrgyzstan",
                "Laos",
                "Latvia",
                "Lebanon",
                "Lesotho",
                "Liberia",
                "Libya",
                "Liechtenstein",
                "Lithuania",
                "Luxembourg",
                "Macedonia",
                "Madagascar",
                "Malawi",
                "Malaysia",
                "Maldives",
                "Mali",
                "Malta",
                "Marshall Islands",
                "Mauritania",
                "Mauritius",
                "Mexico",
                "Micronesia",
                "Moldova",
                "Monaco",
                "Mongolia",
                "Montenegro",
                "Morocco",
                "Mozambique",
                "Myanmar (Burma)",
                "Namibia",
                "Nauru",
                "Nepal",
                "The Netherlands",
                "New Zealand",
                "Nicaragua",
                "Niger",
                "Nigeria",
                "Norway",
                "Oman",
                "Pakistan",
                "Palau",
                "Palestine",
                "Panama",
                "Papua New Guinea",
                "Paraguay",
                "Peru",
                "The Philippines",
                "Poland",
                "Portugal",
                "Qatar",
                "Romania",
                "Russia",
                "Rwanda",
                "St. Kitts & Nevis",
                "St. Lucia",
                "St. Vincent & The Grenadines",
                "Samoa",
                "San Marino",
                "Sao Tome & Principe",
                "Saudi Arabia",
                "Senegal",
                "Serbia",
                "Seychelles",
                "Sierra Leone",
                "Singapore",
                "Slovakia",
                "Slovenia",
                "Solomon Islands",
                "Somalia",
                "South Africa",
                "South Sudan",
                "Spain",
                "Sri Lanka",
                "Sudan",
                "Suriname",
                "Sweden",
                "Switzerland",
                "Syria",
                "Tajikistan",
                "Tanzania",
                "Thailand",
                "Togo",
                "Tonga",
                "Trinidad & Tobago",
                "Tunisia",
                "Turkey",
                "Turkmenistan",
                "Tuvalu",
                "Uganda",
                "Ukraine",
                "United Arab Emirates",
                "United Kingdom",
                "United States of America",
                "Uruguay",
                "Uzbekistan",
                "Vanuatu",
                "Venezuela",
                "Vietnam",
                "Yemen",
                "Zambia",
                "Zimbabwe"
            ],
            firstName: "",
            lastName: "",
            emailId: "",
            countrySelect: "",
            organization: "",
            mainLogin: false,
            thankForSignIn: false,
            message: "",
            username: "",
            password: "",
            errors: {},
            loader: false
        }
    }


    componentDidMount() {
        userTrack("SpeakUp", "Landing");
        let mainLogin = localStorage.getItem("teqbahnSpeakupLogin");

        this.setState({
            mainLogin: mainLogin ? true : false
        })
    }

    validateEmail(email) {
        return email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    }

    clickSignin() {
        let { firstName, lastName, emailId, organization, countrySelect } = this.state;
        let errors = {};
        if (firstName.trim() === "") {
            errors["firstName"] = "First Name is required."
        }
        if (lastName.trim() === "") {
            errors["lastName"] = "Last Name is required."
        }
        if (countrySelect.trim() === "") {
            errors["country"] = "Country is required."
        }
        if (organization.trim() === "") {
            errors["organization"] = "Organization/Profession is required."
        }
        if (emailId.trim() === "") {
            errors["emailId"] = "Email is required."
        } else if (!this.validateEmail(emailId)) {
            errors["emailId"] = "Email is invalid."
        }

        this.setState({
            errors
        })
        if (Object.keys(errors).length === 0) {
            this.setState({
                loader: true
            })
            let postJson = { firstName: firstName, lastName: lastName, email: emailId, country: countrySelect, organization: organization }
            const request = new ZiFetchRequest();
            request.setDomain('speakUp');
            request.setEvent('addToSpeakUpMailingList');
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === 'Invalid Request') {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    userTrack("SpeakUp", "SignIn");
                    let responseData = JSON.parse(response.getMessage());
                    response = responseData.response
                    this.setState({
                        loader: false,
                        mainLogin: true,
                        thankForSignIn: true,
                        firstName: "",
                        lastName: "",
                        emailId: "",
                        countrySelect: "",
                        organization: "",
                        message: response === "Success" ? "Thank you for signing." : "Already subscribed"
                    }, () => {
                        localStorage.setItem("teqbahnSpeakupEmail", emailId)
                        localStorage.setItem("teqbahnSpeakupLogin", true)
                    })
                }
            });
        }
    }

    render() {
        let { loader, errors, country, firstName, lastName, emailId, organization, thankForSignIn, message, countrySelect } = this.state;
        return <div className='speakup-page speakup-v2'>
            <Header/>
            {
                <div className='body pt-5'>
                    <div className='limit-width'>
                        <div className='title'>
                            JOIN THE CALL
                        </div>
                        <div className='content py-5'>
                            I join the global call to end all forms of bullying,&nbsp;discrimination,&nbsp;and&nbsp;harrassment
                            <br />for all professionals and learners in medicine.
                        </div>

                        <div className='row mx-0 '>
                            <div className='col-sm-6'>
                                <div className='form-group'>
                                    <input className={errors["firstName"] ? "form-control is-invalid custom-form-input" : 'form-control custom-form-input'} placeholder='First name' value={firstName} onChange={(e) => {
                                        this.setState({ firstName: e.target.value })
                                    }} />
                                    {
                                        errors["firstName"] && <div className="invalid-feedback">
                                            {errors["firstName"]}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className='form-group'>
                                    <input className={errors["lastName"] ? "form-control is-invalid custom-form-input" : 'form-control custom-form-input'} placeholder='Last name' value={lastName} onChange={(e) => {
                                        this.setState({ lastName: e.target.value })
                                    }} />
                                    {
                                        errors["lastName"] && <div className="invalid-feedback">
                                            {errors["lastName"]}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='row mx-0 '>
                            <div className='col-sm-6'>
                                <div className='form-group'>

                                    <input className={errors["emailId"] ? "form-control is-invalid custom-form-input" : 'form-control custom-form-input'} placeholder='Email' value={emailId} onChange={(e) => {
                                        this.setState({ emailId: e.target.value })
                                    }} />
                                    {
                                        errors["emailId"] && <div className="invalid-feedback">
                                            {errors["emailId"]}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className='form-group'>
                                    <select className={errors["country"] ? "form-control custom-form-input custom-select is-invalid" : 'form-control custom-form-input custom-select'}
                                        value={countrySelect}
                                        onChange={(e) => {
                                            this.setState({ countrySelect: e.target.value })
                                        }}
                                    >
                                        <option value="">Select Country</option>
                                        {
                                            country.map((item, index) => {
                                                return <option key={index} value={item} >{item}</option>
                                            })
                                        }
                                    </select>
                                    {
                                        errors["country"] && <div className="invalid-feedback">
                                            {errors["country"]}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='row mx-0 '>
                            <div className='col-sm-3' />
                            <div className='col-sm-6'>
                                <div className='form-group'>
                                    <input className={errors["organization"] ? "form-control is-invalid custom-form-input" : 'form-control custom-form-input'} placeholder='Organization/Profession' value={organization} onChange={(e) => {
                                        this.setState({ organization: e.target.value })
                                    }} />
                                    {
                                        errors["organization"] && <div className="invalid-feedback">
                                            {errors["organization"]}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='row mx-0 py-2'>
                            <div className='col-sm-4'></div>
                            <div className='col-sm-4'>
                                <button className={`btn btn-block btn-sign ${loader ? "disabled" : ""}`} onClick={() => {
                                    this.clickSignin()
                                }}>
                                    {
                                        loader && <i className="fa fa-spinner fa-spin"></i>
                                    }
                                    &nbsp;CLICK TO SIGN
                                </button>
                            </div>
                        </div>
                        <div className='sign-agree py-2'>
                            *By signing you will be added to our occasional&nbsp;update list. You can opt out from&nbsp;the&nbsp;confirmation&nbsp;email.
                        </div>
                        {thankForSignIn &&
                            <div className='signing-thanks'>
                                {message}
                            </div>
                        }
                        <div className='sign-agree-after-content'>
                            The SpeakUp Coalition is a community of leaders from medical and surgical specialties engaged in a call to action to improve the culture of the medical profession. The Coalition has actively raised awareness on the high prevalence of abusive behaviors in the workplace by:
                            <ul style={{ paddingInlineStart: 20, margin: "10px 0" }}>
                                <li>Delivering strategies and solutions through numerous publications and presentations at the national and international levels;</li>
                                <li>Creating new codes of conduct;</li>
                                <li>Proposing institutional education curriculum changes;</li>
                                <li>Developing policy changes at both the institutional and government level.</li>
                            </ul>
                        </div>
                    </div>
                    <div className={'row mx-0'}>
                        <div className="col-12 " style={{ textAlign: 'center', padding: "20px 0px" }}>
                            <p style={{ color: 'black', fontFamily: 'Helvetica-Regular', fontSize: 30 }}>
                                LEARN MORE
                            </p>
                        </div>
                    </div>
                    <div className='product-images-group'>
                        <div className='product-images'>
                            <div className="project-div">
                                <div className="logo zoom div_height">
                                    <a rel="noreferrer" href="https://physicianjustequity.com/" target="_blank" title="Learn More">
                                        <div className="project_1" style={{}}></div>
                                    </a>
                                </div>
                                <div className="logo zoom div_height">
                                    <a rel="noreferrer" href="https://paritymovement.org/about/" target="_blank" title="Learn More">
                                        <div className="project_2" style={{}}></div>
                                    </a>
                                </div>

                                <div className="logo col-sm-2  zoom div_height">
                                    <a rel="noreferrer" href="https://www.statnews.com/2022/09/08/breaking-the-corporate-medical-playbook-silencing-physicians-reports-inequity/" target="_blank" title="Learn More">
                                        <div className="project_3" style={{}}></div>
                                    </a>
                                </div>
                                <div className="logo zoom div_height">
                                    <a rel="noreferrer" href={MyConstant.keyList.projectUrl + "/speakup/SpeakUpPoweredbyUmed.Me.pdf"} target="_blank" title="Learn More">
                                        <div className="project_7" style={{}}></div>
                                    </a>
                                </div>
                                <div className="logo zoom div_height">
                                    <a rel="noreferrer" href="https://febs.onlinelibrary.wiley.com/doi/full/10.1002/1873-3468.14481" target="_blank" title="Learn More">
                                        <div className="project_4" style={{}}></div>
                                    </a>
                                </div>
                                <div className="logo zoom div_height">
                                    <a rel="noreferrer" href="https://podcasts.apple.com/us/podcast/episode-6-speakup-ortho-and-ending-residency/id1601568503?i=1000548801923" target="_blank" title="Learn More">
                                        <div className="project_5" style={{}}></div>
                                    </a>
                                </div>
                                <div className="logo zoom div_height">
                                    <a rel="noreferrer" href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7992024/?report=reader" target="_blank" title="Learn More">
                                        <div className="project_6" style={{}}></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='umedme-project-div'>
                            <div className='logo'>
                                <div className='round black'>
                                    <img alt="SpeakUp" src={p_1} className="img-fluid" />
                                </div>
                            </div>
                            <div className='logo'>
                                <div className='round'>
                                    <img alt="SpeakUp" src={p_2} className="img-fluid" />
                                </div>
                            </div>
                            <div className='logo'>
                                <div className='round'>
                                    <img alt="SpeakUp" src={p_3} className="img-fluid" />
                                </div>
                            </div>
                            <div className='logo'>
                                <div className='round'>
                                    <a rel="noreferrer" href='https://umed.me/' title='Umed.Me' target="_blank">
                                        <img alt="SpeakUp" src={p_4} className="img-fluid" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* <div style={{ height: 10 }} /> */}

                    </div>
                    <div className="container py-5 px-0">
                        <div className="row text-center">
                            <div className="col-sm-3 my-3 px-1 py-3">
                                <img className="img-fluid" src={Screen1} width={"250px"} />
                            </div>
                            <div className="col-sm-3 my-3 px-1 py-3">
                                <img className="img-fluid" src={Screen2} width={"250px"} />
                            </div>
                            <div className="col-sm-3 my-3 px-1 py-3">
                                <img className="img-fluid" src={Screen3} width={"250px"} />
                            </div>
                            <div className="col-sm-3 my-3 px-1 py-3">
                                <img className="img-fluid" src={Screen4} width={"250px"} />
                            </div>
                        </div>
                    </div>
                    <div className="playstore">
                        <a href={"https://apps.apple.com/in/app/spkup/id6444845897"}>
                            <img className="img-fluid" src={applestore} />
                        </a>
                        <a className='mx-2' href={"https://play.google.com/store/apps/details?id=com.spkup"}>
                            <img className="img-fluid" src={playstore} />
                        </a>
                    </div>
                    
                </div>
            }

            <Footer />
        </div>;
    }
}
