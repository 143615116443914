import Header from "./component/Header";
import homeMain from '../images/speakupv2/LandingPages.png';
import learnMore from '../images/speakupv2/learn-more-bg.jpeg';
import Footer from "./Footer";
import { useEffect, useState } from "react";
import Screen1 from '../images/speakup/Screen1_New.png';
import Screen2 from '../images/speakup/Screen2_New.png';
import Screen3 from '../images/speakup/Screen3_New.png';
import Screen4 from '../images/speakup/Screen4_New.png';
import applestore from '../images/speakup/apple.png'
import playstore from '../images/speakup/playstorea.png'
import p_1 from '../images/speakup/p_1.png';
import p_2 from '../images/speakupv2/p_2.png';
import p_3 from '../images/speakupv2/p_3.png';
import p_4 from '../images/speakupv2/p_4.png';
import lm1 from '../images/speakupv2/lm1.jpeg';
import lm2 from '../images/speakupv2/lm2.jpeg';
import lm3 from '../images/speakupv2/lm3.jpeg';
import lm4 from '../images/speakupv2/lm4.jpeg';
import lm5 from '../images/speakupv2/lm5.jpeg';
import lm6 from '../images/speakupv2/lm6.jpeg';
import lm7 from '../images/speakupv2/lm7.jpeg';
import MyConstant from "../config/MyConstant";
import { userTrack } from "../config/MyFunctions";

const slideContent = [
    {
        name: "Arianna L. Gianakos, DO",
        content: "Professor, Orthopedic Surgery, Yale Orthopedics and Rehabilitation; Founding Member,  SpeakUpOrtho; Global Speak Up Coalition; International Orthopaedic Diversity Alliance; PhD Candidate - Gender Studies"
    },
    {
        name: "Dawn M. Laporte, MD",
        content: "John Hopkins Orthopaedic Residency Program Director; Chair of the ASSH Resident Education Committee; Co-Founder Women of Orthopaedics Worldwide (WOW)"
    },
    {
        name: "Lisa K. Cannada, MD",
        content: "Founding Member,  SpeakUpOrtho; Current BOD: MAOA, COS; Founder OTA Women in Trauma;",
        subContent: "International Diversity Orthopaedic Alliance"
    },
    {
        name: "John G. Kennedy, MD, FRCS",
        content: "Chief of Foot and Ankle Division & Clinical Professor Dept of Orthopaedics (NYU); Founding Member ICSRA; Student/Resident/Fellow Advocate"
    },
    {
        name: "Jennifer Weiss, MD",
        content: "Founding Member,  SpeakUpOrtho; AAOS Board of Directors; Task Force on Physician Wellness (POSNA); International Orthopaedic Diversity Alliance; Physician Just Equity"
    },
    {
        name: "Nancy Yen Shipley, MD",
        content: "Founding Member of SpeakUpOrtho; 6% Podcast with NancyMD; Surgeon/Speaker/Podcast Host/Blogger"
    },
    {
        name: "Mary K. Mulcahey, MD",
        content: "Director of Women’s Sports Medicine (Tulane); RJOS Vice President; AANA Board of Directors",
        subContent: "International Diversity Orthopaedic Alliance"
    },
    {
        name: "Vidya Sri",
        content: "Co-Founder Teqbahn Labs; Co-Founder, Everywoman Treaty; Founder, Gangashakti;",
        subContent: "Initiative on VAW, Harvard Kennedy School"
    },
    {
        name: "Charlie Clements, MD, MPH",
        content: "Co-Founder, Everywoman Treaty; Former President, Physicians for Human Rights; International Campaign to Ban Landmines; Author, Witness to War (Bantam, 1984)",
    },
    {
        name: "Eleanor Nwadinobi, MBBS EMA, FAAC",
        content: "International President of the Medical Women's International Association (MWIA);President, Widows Development Organization; International health, women peace and security, gender and human rights expert",
    },
    {
        name: "Pringl Miller, MD",
        content: "Executive Director,  Physician Just Equity; Founding Member Time’s Up Healthcare; Founder, #WoSurgMeToo; Symposium Editor, Academy of Master Surgeon Educators; 2020 Marquis Who’s Who in America",
    },
    {
        name: "Kellie Stecher, MD",
        content: "American Medical Women's Association; Governor of District Policy Committee Gender Equity Task Force; Minnesota Mental Health Advocates Board Member; Co-Founder/President Patient Care Heroes",
    },
    {
        name: "Julie A. Freischlag, MD",
        content: "CEO Wake Forest School of Medicine; Dean, Atrium Health; Past: President, American College of Surgeons; Council of Deans, AAMC; Chair of Surgery , Surgeon-in-Chief (Johns Hopkins), Chair of Board of Regents, American College of Surgeons, President of Society,  Vascular Surgery",
    },
    {
        name: "Joseph D. Zuckerman, MD",
        content: "Walter A.L. Thompson Professor of Orthopedic Surgery, NYU Grossman School of Medicine; Chair, Dept. of Orthopaedic Surgery; Past President AAOS; Past President American Shoulder and Elbow Surgeons; AOA Distinguished Educator Award  (2015)",
    },
    {
        name: "Mitchel Harris, MD",
        content: "Chief of Orthopaedic Surgery, Harvard-Massachusetts General Hospital",
    },
    {
        name: "Roberta Gerhard, DO",
        content: "Founder, Chair-Emeritus AMWA Gender Equity Task Force; Governing Council Member, AMWA Physician Section; Advisory Board Member, Physician Just Equity; Advisory Board Member, Time’s Up Healthcare",
    },
    {
        name: "Al’ai Alvarez, MD",
        content: "Director of Well-Being, Co-Chair, The Human Potential Team, Director, EM Wellness Fellowship",
        subContent: "Co-Chair, Physician Wellness Forum, Stanford WellMD; Co-chair, Diversity Mentoring Initiative, American College of Emergency Physicians and the Emergency Medicine Residents Association",
    },
    {
        name: "William N. Levine, MD",
        content: "Chair, Dept. of Orthopaedic Surgery (NYP/Columbia); Frank E. Stinchfield Professorship in Orthopedic Surgery; 2021 RJOS HeForShe Award Recipient",
    },
    {
        name: "Wendy Chen, MD, MS",
        content: "Co-Founder, Women of Color PRS; Founder, TimesUp PRS;  LIMITLESS Women in Medicine and Surgery; ACAPS Task Force on Diversity; ASPS Women Plastic Surgeons Steering Committee",
    },
    {
        name: "Melissa Blaker, DO",
        content: "Cardiothoracic Surgeon, Pediatrician, Active Duty Lieutenant Colonel; Partner ,Physician Just Equity; Co-Host, Voices Unheard; Founder, Voices in Medicine",
    },
    {
        name: "Sarah Diekman, MD, JD, MS",
        content: "Occupational & Environmental Medicine; Committee Chair, American College of Legal Medicine Webinar Membership and Diversity Committee;  American Bar Association Science & Technology Law Section",
    },
    {
        name: "Kevin Pei, MD",
        content: "Parkview Health, General Surgery/Critical Care; Associate Chief Academic Research Officer;",
        subContent: "Program Director GS Residency",
    },
    {
        name: "Christopher DiGiovanni, MD",
        content: "Professor of Orthopaedic Surgery, Harvard Medical School;  Chief Emeritus, Foot & Ankle Service;  Vice Chair of Academic Affairs; Past President, AOFAS",
    },
]
function SpeakUpHome(props) {
    let [slidewidth, setSlidewidth] = useState(0);
    let [sliderWidth, setSliderWidth] = useState(0);
    let [sliderActive, setSliderActive] = useState(0);
    let [sliderTransform, setSliderTransform] = useState(0);

    useEffect(() => {
        userTrack("SpeakUp Home", "Landing");
        updateDimensions()
    }, []);



    function updateDimensions() {
        let deviceWidth = document.body.clientWidth;
        var width = deviceWidth / 3;
        if (deviceWidth < 900) {
            width = deviceWidth
        }

        let sliderWidth = slideContent.length * width;
        var sliderTransform = sliderActive * width;

        setSlidewidth(width)
        setSliderWidth(sliderWidth)
        setSliderTransform(sliderTransform)
    }

    function moveSlide(action) {
        let length = slideContent.length;
        let changeSlide = false;
        let currentSliderActive;
        if (action === "next") {
            currentSliderActive = sliderActive + 1;
            if ((currentSliderActive + 1) < length) {
                changeSlide = true;
            }
        } else {
            currentSliderActive = sliderActive - 1;
            if (currentSliderActive >= 0) {
                changeSlide = true;
            }
        }
        if (changeSlide) {
            let deviceWidth = document.body.clientWidth;
            var width = deviceWidth / 2;
            if (deviceWidth < 900) {
                width = deviceWidth
            }

            var sliderTransform = -(currentSliderActive * slidewidth);

            setSliderActive(currentSliderActive)
            setSliderTransform(sliderTransform)
        }

    }

    return <div className='speakup-page'>
        <div className="speakup-v2-page">
            <Header />
            <div className="bg">
                <img src={homeMain} className="background" />
            </div>
            <div className="content-container my-3">
                <div className="content home-page-first">
                    <p>The Global SpeakUp Coalition is a community of leaders from medicine, public health, and human rights, engaged in a call to action to improve the culture of the medical profession. The Coalition has actively raised awareness on the urgent need to drive positive workplace culture by:</p>
                </div>
            </div>
            {/* <div className="slider-outer mb-3">
                {
                    sliderActive > 0 && <div className="move-slide prev">
                        <div className="button" onClick={() => moveSlide('prev')}>
                            <i className="fa fa-arrow-left"></i>
                        </div>
                    </div>
                }
                {
                    (sliderActive + 2) < slideContent.length && <div className="move-slide next">
                        <div className="button" onClick={() => moveSlide('next')}>
                            <i className="fa fa-arrow-right"></i>
                        </div>
                    </div>
                }

                <div className="slider" style={{ width: sliderWidth, transform: `translate3d(${sliderTransform}px, 0px, 0px)` }}>
                    {slideContent.map((value, index) => {
                        return <div key={index} className="slide" style={{ width: slidewidth }}>
                            <div className="slideInner">
                                <p className="content">{value.name}</p>
                                <p className="writer">{value.content}</p>
                                <p className="px-2 pt-2 writer">{value.subContent}</p>
                            </div>
                        </div>
                    })}
                </div>
            </div> */}
            <div className="content-container mb-3 py-5 red-bg-content">
                <div className="content text-left" style={{ fontSize: 20 }}>
                    <ul>
                        <li>
                            <p>Delivering strategies and solutions through numerous publications and presentations at the national and international levels; </p>
                        </li>
                        <li>
                            <p>Creating new codes of conduct; </p>
                        </li>
                        <li>
                            <p>Proposing institutional education curriculum changes;</p>
                        </li>
                        <li>
                            <p>Developing policy changes at both the institutional and government level.</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="container py-5 px-0">
                <div className="text-center mb-3">
                    <h2>The SpeakUp App</h2>
                </div>
                <div className="row text-center">
                    <div className="col-sm-3 my-3 px-1 py-3">
                        <img className="img-fluid" src={Screen1} width={"250px"} />
                    </div>
                    <div className="col-sm-3 my-3 px-1 py-3">
                        <img className="img-fluid" src={Screen2} width={"250px"} />
                    </div>
                    <div className="col-sm-3 my-3 px-1 py-3">
                        <img className="img-fluid" src={Screen3} width={"250px"} />
                    </div>
                    <div className="col-sm-3 my-3 px-1 py-3">
                        <img className="img-fluid" src={Screen4} width={"250px"} />
                    </div>
                </div>
            </div>
            <div className="learn-more">
                <div className="bg">
                    <img src={learnMore} />
                </div>
                <div className="text">
                    LEARN MORE
                </div>
            </div>
            <div className="mb-3 py-5">
                <div className="content">
                    <div className='umedme-project-div'>
                        <div className='logo'>
                            <div className='round'>
                                <a rel="noreferrer" href='https://physicianjustequity.com/' title='Learn More' target="_blank">
                                    <img alt="SpeakUp" src={lm1} className="img-fluid" />
                                </a>
                            </div>
                            <div className='round'>
                                <a rel="noreferrer" href='https://www.statnews.com/2022/09/08/breaking-the-corporate-medical-playbook-silencing-physicians-reports-inequity/' title='Learn More' target="_blank">
                                    <img alt="SpeakUp" src={lm2} className="img-fluid" />
                                </a>
                            </div>
                            <div className='round'>
                                <a rel="noreferrer" href='https://paritymovement.org/about/' title='Learn More' target="_blank">
                                    <img alt="SpeakUp" src={lm3} className="img-fluid" />
                                </a>
                            </div>
                            <div className='round'>
                                <a rel="noreferrer" href='https://febs.onlinelibrary.wiley.com/doi/full/10.1002/1873-3468.14481' title='Learn More' target="_blank">
                                    <img alt="SpeakUp" src={lm4} className="img-fluid" />
                                </a>
                            </div>
                            <div className='round'>
                                <a rel="noreferrer" href={MyConstant.keyList.projectUrl + "/speakup/SpeakUpPoweredbyUmed.Me.pdf"} title='Learn More' target="_blank">
                                    <img alt="SpeakUp" src={lm5} className="img-fluid" />
                                </a>
                            </div>
                            <div className='round'>
                                <a rel="noreferrer" href='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7992024/?report=reader' title='Learn More' target="_blank">
                                    <img alt="SpeakUp" src={lm6} className="img-fluid" />
                                </a>
                            </div>
                            <div className='round'>
                                <a rel="noreferrer" href='https://podcasts.apple.com/us/podcast/episode-6-speakup-ortho-and-ending-residency/id1601568503?i=1000548801923' title='Umed.Me' target="_blank">
                                    <img alt="SpeakUp" src={lm7} className="img-fluid" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-container py-5 red-bg-content">
                <div className="content">
                    <div className='umedme-project-div'>
                        <div className='logo'>
                            <div className='round black'>
                                <img alt="SpeakUp" src={p_1} className="img-fluid" />
                            </div>
                        </div>
                        <div className='logo'>
                            <div className='round black'>
                                <img alt="SpeakUp" src={p_2} className="img-fluid" />
                            </div>
                        </div>
                        <div className='logo'>
                            <div className='round black'>
                                <img alt="SpeakUp" src={p_3} className="img-fluid" />
                            </div>
                        </div>
                        <div className='logo'>
                            <div className='round black'>
                                <a rel="noreferrer" href='https://umed.me/' title='Umed.Me' target="_blank">
                                    <img alt="SpeakUp" src={p_4} className="img-fluid" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-container py-5 red-bg-content">
                <div className="content">
                    <h2>Download Now!</h2>
                    <div className="pt-5">
                        <a className='mx-2' href={"https://apps.apple.com/in/app/spkup/id6444845897"}>
                            <img className="img-fluid" src={applestore} />
                        </a>
                        <a className='mx-2' href={"https://play.google.com/store/apps/details?id=com.spkup"}>
                            <img className="img-fluid" src={playstore} />
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>;
}

export default SpeakUpHome