import React from 'react';
import Select from 'react-select';

export default class Select2 extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Select
                {...this.props}
                menuPortalTarget={document.body}
                styles={{ ...this.props.styles, menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            />
        )
    }
}