import React from 'react';
import { Link } from 'react-router-dom';
import MyConstant from '../config/MyConstant';
import logo from '../images/speakupv2/speakup-logo.png';
import feedback from '../images/speakup/feedback2.png';
import sharestory from '../images/speakup/sharestorywhite.png';

export default class Header extends React.Component {
    render() {

        return <div className='header'>
            <div className='main-logo'>
                <Link to={MyConstant.keyList.projectUrl}>
                    <img alt="SpeakUp" className='header-logo' src={logo} />
                </Link>
            </div>
            <div className='story-logo'>
                <Link to={MyConstant.keyList.projectUrl+"/share-story"}>
                    <img alt="SpeakUp" className='header-logo' src={sharestory} />
                </Link>
            </div>
        </div>;
    }
}
