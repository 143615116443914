import React from 'react';
import Header from "./component/Header";
import Footer from "./Footer";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel'
import Slide1 from '../images/overview/1.png';
import Slide2 from '../images/overview/2.png';
import Slide3 from '../images/overview/3.png';
import Slide4 from '../images/overview/4.png';
import Slide5 from '../images/overview/5.png';
import Slide6 from '../images/overview/6.png';
import Slide7 from '../images/overview/7.png';
import Slide8 from '../images/overview/8.png';
import Slide9 from '../images/overview/9.png';
import Slide10 from '../images/overview/10.png';
import Slide11 from '../images/overview/11.png';
import Slide12 from '../images/overview/12.png';
import Slide13 from '../images/overview/13.png';
import Slide14 from '../images/overview/14.png';
import Slide15 from '../images/overview/15.png';
import Slide16 from '../images/overview/16.png';
import { userTrack } from '../config/MyFunctions';


class OverView extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            holdImage: true,
            oneTimeSet: false,
        }
    }

    componentDidMount() {
        userTrack("SpeakUp Overview", "Landing");
    }

    changeStatus(type) {
        this.setState({ holdImage: type })
    }
    render() {
        let { holdImage } = this.state
        return <div className='speakup-page speakup-over-view'>
            <div className="speakup-v2-page ">
                <Header />
            </div>
            <div className="responsive">
                <Carousel autoPlay={holdImage} interval={5000} width={"100%"} infiniteLoop={true} stopOnHover={false} showArrows={true} showStatus={false}
                    renderArrowPrev={(onClickHandler, hasPrev, label) =>
                        hasPrev && (
                            <span onClick={onClickHandler}>
                                <a href="javascript:void(0)" onClick={() => {
                                    this.changeStatus(true)
                                }} className='btn btn-sm  prevbtn'>
                                    <i className='fa fa-chevron-left' style={{ fontSize: "26px", color: "#e2915b" }} />
                                </a>
                            </span>
                        )
                    }
                    renderArrowNext={(onClickHandler, hasNext, label) =>
                        hasNext && (
                            <span onClick={onClickHandler}>
                                <a href="javascript:void(0)" onClick={() => {
                                    this.changeStatus(true)
                                }} title={label} className='btn btn-sm nextbtn' >
                                    <i className='fa fa-chevron-right' style={{ fontSize: "26px", color: "#e2915b" }} />
                                </a>
                            </span>
                        )
                    }
                    showThumbs={false}
                    animationHandler="fade"

                >

                    <div className='' onClick={() => {
                        this.changeStatus(false)
                    }} >
                        <img src={Slide1} className='carosal_img' style={{ height: window.innerWidth > window.innerHeight ? (window.innerHeight - 70) : "auto" }}
                        />
                    </div>
                    <div onClick={() => {
                        this.changeStatus(false)
                    }}>
                        <img src={Slide2} style={{ height: window.innerWidth > window.innerHeight ? (window.innerHeight - 70) : "auto" }}
                        />
                    </div>

                    <div onClick={() => {
                        this.changeStatus(false)
                    }}>
                        <img src={Slide3} style={{ height: window.innerWidth > window.innerHeight ? (window.innerHeight - 70) : "auto" }}
                        />
                    </div>
                    <div onClick={() => {
                        this.changeStatus(false)
                    }}>
                        <img src={Slide4} style={{ height: window.innerWidth > window.innerHeight ? (window.innerHeight - 70) : "auto" }}
                        />
                    </div>
                    <div onClick={() => {
                        this.changeStatus(false)
                    }}>
                        <img src={Slide5} style={{ height: window.innerWidth > window.innerHeight ? (window.innerHeight - 70) : "auto" }}
                        />
                    </div>
                    <div onClick={() => {
                        this.changeStatus(false)
                    }}>
                        <img src={Slide6} style={{ height: window.innerWidth > window.innerHeight ? (window.innerHeight - 70) : "auto" }}
                        />
                    </div>
                    <div onClick={() => {
                        this.changeStatus(false)
                    }}>
                        <img src={Slide7} style={{ height: window.innerWidth > window.innerHeight ? (window.innerHeight - 70) : "auto" }}
                        />
                    </div>
                    <div onClick={() => {
                        this.changeStatus(false)
                    }}>
                        <img src={Slide8} style={{ height: window.innerWidth > window.innerHeight ? (window.innerHeight - 70) : "auto" }}
                        />
                    </div>
                    <div onClick={() => {
                        this.changeStatus(false)
                    }}>
                        <img src={Slide9} style={{ height: window.innerWidth > window.innerHeight ? (window.innerHeight - 70) : "auto" }}
                        />
                    </div>
                    <div onClick={() => {
                        this.changeStatus(false)
                    }}>
                        <img src={Slide10} style={{ height: window.innerWidth > window.innerHeight ? (window.innerHeight - 70) : "auto" }}
                        />
                    </div>
                    <div onClick={() => {
                        this.changeStatus(false)
                    }}>
                        <img src={Slide11} style={{ height: window.innerWidth > window.innerHeight ? (window.innerHeight - 70) : "auto" }}
                        />
                    </div>
                    <div onClick={() => {
                        this.changeStatus(false)
                    }}>
                        <img src={Slide12} style={{ height: window.innerWidth > window.innerHeight ? (window.innerHeight - 70) : "auto" }}
                        />
                    </div>
                    <div onClick={() => {
                        this.changeStatus(false)
                    }}>
                        <img src={Slide13} style={{ height: window.innerWidth > window.innerHeight ? (window.innerHeight - 70) : "auto" }}
                        />
                    </div>
                    <div onClick={() => {
                        this.changeStatus(false)
                    }}>
                        <img src={Slide14} style={{ height: window.innerWidth > window.innerHeight ? (window.innerHeight - 70) : "auto" }}
                        />
                    </div>
                    <div onClick={() => {
                        this.changeStatus(false)
                    }}>
                        <img src={Slide15} style={{ height: window.innerWidth > window.innerHeight ? (window.innerHeight - 70) : "auto" }}
                        />
                    </div>
                    <div onClick={() => {
                        this.changeStatus(false)
                    }}>
                        <img src={Slide16} style={{ height: window.innerWidth > window.innerHeight ? (window.innerHeight - 70) : "auto" }}
                        />
                    </div>
                </Carousel>
            </div>
            <Footer />
        </div>;
    }

}
export default OverView