import Header from "./component/Header";
import AboutUsImg from '../images/speakupv2/AboutUs.png';
import Footer from "./Footer";
import { useEffect } from "react";
import { userTrack } from "../config/MyFunctions";
function AboutUs(props) {


    useEffect(() => {
        userTrack("SpeakUp About Us", "Landing");
    }, [])

    return <div className='speakup-page'>
        <div className="speakup-v2-page about-us">
            <Header />
            <div className="bg">
                <img src={AboutUsImg} className="img-fluid" />
            </div>
            <div className="content-container mb-3 py-5">
                <div className="content text-left who-list">
                    <div className="mb-3">
                        <h2>Global SpeakUp Coalition Members</h2>
                    </div>
                    Arianna L. Gianakos, DO <br/>
                    Professor, Orthopedic Surgery, Yale Orthopedics and Rehabilitation; Founding Member,  SpeakUpOrtho; Global Speak Up Coalition; International Orthopaedic Diversity Alliance; PhD Candidate - Gender Studies<br/>
                    <br/>
                    Dawn M. Laporte, MD<br/>
                    John Hopkins Orthopaedic Residency Program Director; Chair of the ASSH Resident Education Committee; Co-Founder Women of Orthopaedics Worldwide (WOW)<br/>
                    <br/>
                    Lisa K. Cannada, MD<br/>
                    Founding Member,  SpeakUpOrtho; Current BOD: MAOA, COS; Founder OTA Women in Trauma;<br/>
                    International Diversity Orthopaedic Alliance<br/>
                    <br/>
                    John G. Kennedy, MD, FRCS<br/>
                    Chief of Foot and Ankle Division & Clinical Professor Dept of Orthopaedics (NYU); Founding Member ICSRA; Student/Resident/Fellow Advocate<br/>
                    <br/>
                    Jennifer Weiss, MD<br/>
                    Founding Member,  SpeakUpOrtho; AAOS Board of Directors; Task Force on Physician Wellness (POSNA); International Orthopaedic Diversity Alliance; Physician Just Equity<br/>
                    <br/>
                    Nancy Yen Shipley, MD<br/>
                    Founding Member of SpeakUpOrtho; 6% Podcast with NancyMD; Surgeon/Speaker/Podcast Host/Blogger<br/>
                    <br/>
                    Mary K. Mulcahey, MD<br/>
                    Director of Women’s Sports Medicine (Tulane); RJOS Vice President; AANA Board of Directors<br/>
                    International Diversity Orthopaedic Alliance<br/>
                    <br/>
                    Vidya Sri<br/>
                    Co-Founder Teqbahn Labs; Co-Founder, Everywoman Treaty; Founder, Gangashakti;<br/>
                    Initiative on VAW, Harvard Kennedy School<br/>
                    <br/>
                    Charlie Clements, MD, MPH<br/>
                    Co-Founder, Everywoman Treaty; Former President, Physicians for Human Rights; International Campaign to Ban Landmines; Author, Witness to War (Bantam, 1984)<br/>
                    <br/>
                    Eleanor Nwadinobi, MBBS EMA, FAAC<br/>
                    International President of the Medical Women's International Association (MWIA);President, Widows Development Organization; International health, women peace and security, gender and human rights expert<br/>
                    <br/>
                    Pringl Miller, MD<br/>
                    Executive Director,  Physician Just Equity; Founding Member Time’s Up Healthcare; Founder, #WoSurgMeToo; Symposium Editor, Academy of Master Surgeon Educators; 2020 Marquis Who’s Who in America<br/>
                    <br/>
                    Kellie Stecher, MD<br/>
                    American Medical Women's Association; Governor of District Policy Committee Gender Equity Task Force; Minnesota Mental Health Advocates Board Member; Co-Founder/President Patient Care Heroes<br/>
                    <br/>
                    Julie A. Freischlag, MD<br/>
                    CEO Wake Forest School of Medicine; Dean, Atrium Health; Past: President, American College of Surgeons; Council of Deans, AAMC; Chair of Surgery , Surgeon-in-Chief (Johns Hopkins), Chair of Board of Regents, American College of Surgeons, President of Society,  Vascular Surgery<br/>
                    <br/>
                    Joseph D. Zuckerman, MD<br/>
                    Walter A.L. Thompson Professor of Orthopedic Surgery, NYU Grossman School of Medicine; Chair, Dept. of Orthopaedic Surgery; Past President AAOS; Past President American Shoulder and Elbow Surgeons; AOA Distinguished Educator Award  (2015)<br/>
                    <br/>
                    Mitchel Harris, MD<br/>
                    Chief of Orthopaedic Surgery, Harvard-Massachusetts General Hospital<br/>
                    <br/>
                    Roberta Gerhard, DO<br/>
                    Founder, Chair-Emeritus AMWA Gender Equity Task Force; Governing Council Member, AMWA Physician Section; Advisory Board Member, Physician Just Equity; Advisory Board Member, Time’s Up Healthcare<br/>
                    <br/>
                    Al’ai Alvarez, MD<br/>
                    Director of Well-Being, Co-Chair, The Human Potential Team, Director, EM Wellness Fellowship<br/>
                    Co-Chair, Physician Wellness Forum, Stanford WellMD; Co-chair, Diversity Mentoring Initiative, American College of Emergency Physicians and the Emergency Medicine Residents Association<br/>
                    <br/>
                    William N. Levine, MD<br/>
                    Chair, Dept. of Orthopaedic Surgery (NYP/Columbia); Frank E. Stinchfield Professorship in Orthopedic Surgery; 2021 RJOS HeForShe Award Recipient<br/>
                    <br/>
                    Wendy Chen, MD, MS<br/>
                    Co-Founder, Women of Color PRS; Founder, TimesUp PRS;  LIMITLESS Women in Medicine and Surgery; ACAPS Task Force on Diversity; ASPS Women Plastic Surgeons Steering Committee<br/>
                    <br/>
                    Melissa Blaker, DO<br/>
                    Cardiothoracic Surgeon, Pediatrician, Active Duty Lieutenant Colonel; Partner ,Physician Just Equity; Co-Host, Voices Unheard; Founder, Voices in Medicine<br/>
                    <br/>
                    Sarah Diekman, MD, JD, MS<br/>
                    Occupational & Environmental Medicine; Committee Chair, American College of Legal Medicine Webinar Membership and Diversity Committee;  American Bar Association Science & Technology Law Section<br/>
                    <br/>
                    Kevin Pei, MD<br/>
                    Parkview Health, General Surgery/Critical Care; Associate Chief Academic Research Officer;<br/>
                    Program Director GS Residency<br/>
                    <br/>
                    Christopher DiGiovanni, MD<br/>
                    Professor of Orthopaedic Surgery, Harvard Medical School;  Chief Emeritus, Foot & Ankle Service;  Vice Chair of Academic Affairs; Past President, AOFAS<br/>
                </div>
            </div>
        </div>
        <Footer />
    </div>;
}

export default AboutUs