module.exports = {
  siteKey: "6LdYCvUhAAAAANAQE2zYUpFNCrZCvA4PRPjUJwBE",
  secretKey: "6LdYCvUhAAAAAEDl8mPBKoIDCh2o5QjbiAMMG_CT",
  andriodOsVersion: [
    { value: 'Android 11', label: 'Android 11' },
    { value: 'Android 10', label: 'Android 10' },
    { value: 'Pie', label: 'Pie' },
    { value: 'Oreo', label: 'Oreo' },
    { value: 'Nougat (7.1.0 - 7.1.2)', label: 'Nougat (7.1.0 - 7.1.2)' },
    { value: 'Nougat (7.0)', label: 'Nougat (7.0)' },
    { value: 'Marshmallow', label: 'Marshmallow' },
    { value: 'Lollipop', label: 'Lollipop' },
    { value: 'Others', label: 'Others' },
  ],
  iosOsVersion: [
    { value: '15.3.1', label: '15.3.1' },
    { value: '15.4 Beta 2', label: '15.4 Beta 2' },
    { value: '14.8.1', label: '14.8.1' },
    { value: '13.7', label: '13.7' },
    { value: '12.5.5', label: '12.5.5' },
    { value: '11.4.1', label: '11.4.1' },
    { value: '10.3.4', label: '10.3.4' },
    { value: '10.3.3', label: '10.3.3' },
    { value: '9.3.6', label: '9.3.6' },
    { value: '8.4.1', label: '8.4.1' },
    { value: 'Others', label: 'Others' },
  ],
  issueBeingTested: "Issue being tested",
};
