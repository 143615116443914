import React from 'react';
import logo from '../images/speakup/speakupLogo.png';
import Footer from './Footer';

export default class SpeakUp extends React.Component {
    render() {
        return <div className='speakup-page'>
            <div className='' style={{
                height: '40vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <img alt="SpeakUp" className='header-logo' src={logo} style={{ height: "auto" }} />
            </div>
            <div className='body'>
                <div className='coming-soon'>
                    Coming Soon
                </div>
            </div>
            <Footer />
        </div>;
    }
}
