module.exports = {
  keyList: {
    projectName: 'SpeakUp',
    projectUrl: '',
    speakupApiURL: "https://api.teqbahn.com/speakup-api/",
    speakupGrpcURL: "https://api.teqbahn.com",
    apiURL: 'https://api.teqbahn.com/umedme-api/',
    grpcURL: 'https://api.teqbahn.com',
    ipURL: 'https://apis.teqbahn.com/teqe-api/',
  }
}