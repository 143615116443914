import React from 'react';
import Footer from './Footer';
import Header from './Header';

class PrivacyPolicy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    async componentDidMount() {
        window.scrollTo(0, 0);

    }

    render() {
        return (
            <div className='speakup-page'>
                <Header />
                <main>
                    <div className="container px-0">
                        <div className="row">
                            <div className="col-12">
                                <div style={{ padding: 20, fontSize: 14 }}>
                                    <p className="header1">Privacy Policy</p>

                                    <p>Effective: February 2021</p>
                                    <br />

                                    <p style={{ paddingTop: 10 }}>Teqbahn is a co-founder collective with a different journey for a different future. We are inclusive leaders, building an equitable future, with a diversity of perspectives using sustainable technology. We believe in a new model focused on the daily practice of inclusion, equity, diversity, and sustainability. We practice responsible innovation (T), reduce structural inequalities (eq), and increase access to resources (bahn).
                                    </p>
                                    <p style={{ paddingTop: 10 }}>
                                        We have created this privacy statement to inform site visitors (“you”) of our data collection, use, and disclosure practices. This privacy statement applies to novelcoronanews.org and any other website that links to this privacy statement (collectively, the “Site,” and the services available through our Site, collectively, the “Services”). Please read this privacy statement carefully. By using the Site or the Services, you agree that your personal information may be collected, used, and disclosed in the ways we describe. Please do not visit the Site or use the Services if you do not agree with the practices described herein.
                                    </p>
                                    <p className="header1">What Personal Data We Collect and Why We Collect It</p>
                                    <p>When we use the term “personal data,” we mean information that can be used to
                                        identify you as an individual person. We may collect and use the following
                                        categories of personal data through your use of the Site or the Services:</p>
                                    <ul>


                                        <li>
                                            Contact information shown in the comments form, such as the name, location, and
                                            email address, which we use to respond to you and answer your questions;
                                        </li>
                                        <li>
                                            The visitor’s IP address and browser user agent string to help spam detection;
                                        </li>
                                        <li>
                                            The content of any comments you choose to make via email or blog post or any other
                                            format;
                                        </li>
                                        <li>
                                            Communication preferences, including marketing preferences, which we use to manage
                                            how we engage with you;
                                        </li>
                                        <li>
                                            Responses to surveys, which we use to tailor our content and communications to you;
                                            and
                                        </li>
                                        <li>
                                            Inquiries you make, including the content of your voice, email, text, or other
                                            electronic messages, which we use to respond to your inquiries.
                                        </li>
                                    </ul>
                                    <p className="header1">Your Contact Information</p>

                                    <p>
                                        We do not share the information you’ve given us with unaffiliated groups without your
                                        explicit permission.

                                    </p>

                                    <p>
                                        Your name and any information you’ve given us permission to post to the Site will be
                                        available to all users of the Site and the Services.

                                    </p>

                                    <p className="header1">Information Collected Automatically</p>

                                    <p>
                                        We use various technologies to collect other types of data that do not directly reveal
                                        your identity (“Other Information”). If we combine or associate Other Information with
                                        personal data we have collected from you, we will treat the combined information as
                                        personal data in accordance with this privacy statement.

                                    </p>

                                    <p>These technologies include the following:</p>

                                    <p>
                                        Logging Functionality: As is true of most websites, we gather certain information
                                        automatically and store it in log files. This information may include IP addresses,
                                        browser type, internet service provider, referring/exit pages, operating system,
                                        date/time stamp, page views, and/or clickstream data. We generally only use this data
                                        for purposes such as tracking traffic patterns on the Site, security, fraud detection,
                                        and protecting our rights.

                                    </p>

                                    <p>
                                        Cookies and Other Data Collection Technologies: We and our affiliated companies,
                                        business partners, and service providers use cookies and similar technologies to manage
                                        the Site and the Services and to collect and track information about you and your
                                        activities online over time and across different websites and social media channels. In
                                        addition, we use technologies that can collect and store your information to help us
                                        recognize you and enhance your experience on the Site and your use of the Services. For
                                        example, the “remember me” function saves a cookie on your computer’s browser so that
                                        you can be automatically logged in when interacting with the Site or using the Services.
                                        Cookies also help us provide state- or region-specific information and relevant news
                                        that may be of the most interest to you.

                                    </p>

                                    <p>Most internet browsers allow you to remove, disable, or manage cookie functions and adjust your privacy and security preferences. For information on how to do this, access the “help” menu on your internet browser, or visit <a
                                        href="http://www.aboutcookies.org/how-to-control-cookies/">http://www.aboutcookies.org/how-to-control-cookies</a>.
                                    </p>
                                    <p>Please note, however, that disabling cookies on the Site may result in your inability to take full advantage of all the features of the Site or of the Services.</p>


                                    <p>Analytics: We use analytics providers to help us evaluate and measure the use and performance of the Site and use of the Services. To opt out of the aggregation and analysis of data collected about you, write to support@teqbahn.com. These analytics providers may use cookies and other tracking technologies to perform their services. We do not share your personal information with these third parties.</p>

                                    <p className="header1">Additional Uses of Personal Information</p>
                                    <p>In addition to the uses described above, we may use your Personal Information for the
                                        following purposes:</p>
                                    <ul>
                                        <li>
                                            Contacting you to respond to your requests or inquiries;
                                        </li>
                                        <li>
                                            Acknowledging contributions and thanking you if you choose to submit comments or make a donation;
                                        </li>
                                        <li>
                                            Conducting and supporting additional community building efforts;
                                        </li>
                                        <li>Providing you with newsletters, alerts, and other information tailored to your interests;</li>
                                        <li>
                                            Contacting you about programs or events, that we believe may be of interest to you;
                                        </li>
                                        <li>
                                            Conducting surveys, internal research, and analytics to help us understand trends and improve our services;
                                        </li>
                                        <li>
                                            Preventing, investigating, or providing notice of fraud, unlawful or criminal activity, or unauthorized access to or use of personal data, the Site, the Services or our data systems, or to meet legal obligations;
                                        </li>
                                        <li>
                                            Investigating and resolving disputes and security issues and enforcing our terms of use and other agreements;
                                        </li>
                                        <li>
                                            Complying with the law, a judicial proceeding, court order, or other legal process, such as in response to a court order or a subpoena; and
                                        </li>
                                        <li>
                                            Any other lawful, legitimate business purpose.
                                        </li>
                                    </ul>
                                    <p>We may also use services provided by third parties (such as social media platforms) to serve targeted ads to you on third-party platforms. We may do this by providing a hashed, de-identified version of your personal data to the third party for matching purposes.</p>

                                    <p className="header1">How We Share and Disclose Personal Data</p>
                                    <p>We share your personal data with third parties only in ways consistent with this privacy
                                        statement.</p>
                                    <p>Service Providers: We share your personal data with third-party service providers who
                                        perform services on our behalf or for your benefit, such as web hosting and analytics.
                                        We use service providers to provide online payment processing services, but we do not
                                        collect or store your payment card information, bank account numbers, or other financial
                                        data sent directly to those third-party payment processors.</p>

                                    <p className="header1">Media</p>
                                    <p>If you upload images to the website, you should avoid uploading images with embedded location data (EXIF GPS) included. Visitors to the website can download and extract any location data from images on the website.</p>

                                    <p className="header1">Third-Party Links</p>
                                    <p>Our Site and Services may contain links to third-party websites. Any access to and use of such linked websites is not governed by this privacy policy, but is instead governed by the privacy policies of those third-party websites. We are not responsible for the information practices of such third-party websites.</p>

                                    <p className="header1">Security of My Personal Information </p>
                                    <p>We have implemented commercially reasonable precautions to protect the information we collect from loss, misuse, and unauthorized access, disclosure, alteration, and destruction. Please be aware that despite our efforts, no data security measures can take 100% security.
                                        You should take steps to protect against unauthorized access to your password, phone, and computer by, among other things, signing off after using a shared computer, choosing a robust password that nobody else knows or can easily guess, and keeping your login and password private. We are not responsible for any lost, stolen, or compromised passwords or for any activity on your account via unauthorized password activity.
                                    </p>

                                    <p className="header1">What Choices do I Have Regarding Use of My Personal Information?</p>
                                    <p>We may send periodic promotional or informational emails to you. You may opt out of such communications by following the opt-out instructions contained in the email. Please note that it may take up to 10 business days for us to process opt-out requests. If you opt out of receiving emails about recommendations or other information we think may interest you, we may still send you emails about your account or any Services you have requested or received from us.</p>

                                    <p className="header1">Children’s Privacy</p>
                                    <p>Our Services are not directed to and we do not intend to or knowingly collect or solicit personal data online from children under the age of 13. We encourage parents or guardians to participate in and monitor their children’s online activity. If a child under 13 has provided personal data to us, we encourage the child’s parent or guardian to contact us to request that we remove the information from our systems. If you are under the age of 13, do not provide us with any personal data.</p>

                                    <p className="header1">Updates to This Privacy Statement</p>
                                    <p>This privacy statement is current as of the date set forth above. We may change this privacy statement from time to time in our sole discretion, so please be sure to check back periodically. Any and all changes will be reflected on this page and we will update the effective date at the top of this page.</p>
                                    <p>If you have any questions about the privacy aspects of our Services or would like to make a complaint, please contact us at: support@teqbahn.com. </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </main>
                <Footer />
            </div>
        );
    }
}

export default PrivacyPolicy
