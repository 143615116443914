import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MyConstant from '../config/MyConstant';

export default class ManageLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            UserName: "",
            Password: "",
            errors: {
                UserName: "",
                Password: "",
                deletedUser: ""
            },
            passwordVisible: false

        }
    }

    getLogin() {

        let { UserName, Password, errors } = this.state
        //loginId: String, password: String
        if (!UserName) {
            errors.UserName = "Please enter the Username"
        } else {
            errors.UserName = ""
        }

        if (!Password) {
            errors.Password = "Please enter the Password"

        } else {
            errors.Password = ""
        }

        this.setState({ errors })

        if (Password == "" || UserName == "") {
            return false
        }
        
        if(UserName === "speakup" && Password === "0s6N68Tw") {
            localStorage.setItem("SpeakupLogin", "yes")
            window.location = MyConstant.keyList.projectUrl+"/test"
        } else {
            alert("Username or Passsword does not match")
        }

    }
    render() {
        let { UserName, Password, errors, passwordVisible } = this.state
        return (

            <React.Fragment>
                <main className="login-body d-flex justify-content-center align-items-center layout-green-login">
                    <div className='login-box' style={{ color: "#fff" }} >
                        <div className='text-center pt-5 pb-2' style={{ color: "#fff" }}>
                            <h4>Manage Login</h4>
                        </div>
                        <div>
                            <form action="">
                                <div className='row'>
                                    <div className='offset-sm-2 col-sm-8'>
                                        <div className="form-group">
                                            <label>Username</label>
                                            <input type="email" name="email" id="email"
                                                className={"form-control"}
                                                placeholder="Username" value={UserName} onChange={(e) => {
                                                    this.setState({ UserName: e.target.value })
                                                }} />
                                            <div className="custom-invalid-feedback">{errors.UserName}</div>
                                        </div>
                                        <div className="form-group mb-4 ">
                                            <label>Password</label>
                                            <div className='input-group'>
                                                <input type={passwordVisible ? "text" : "password"} name="password" id="password"
                                                    className={"form-control password-radius"}
                                                    placeholder="***********" value={Password} onChange={(e) => {
                                                        this.setState({ Password: e.target.value })
                                                    }} />
                                                <div className="input-group-append" onClick={() => {
                                                    this.setState({ passwordVisible: !passwordVisible })
                                                }}>
                                                    <span className="input-group-text border-0 cursor-pointer password-hint-radius"><i className={`fa ${passwordVisible ? "fa-eye-slash" : "fa-eye"}`} /></span>
                                                </div>
                                            </div>
                                            <div className="custom-invalid-feedback">{errors.Password}</div>
                                        </div>
                                        <button type="button" className="btn btn-block login-btn mb-4" onClick={() => this.getLogin()}>Login</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        )
    }
}