import react from "react";
function WelcomeBanner(props) {
    return <div className="welcome-banner">
        <div className="health-summit">
            ¡El Lanzamiento Oficial de la Aplicación de Migración Umed.Me en la <br />
            Hermosa e Histórica Ciudad de Tijuana del 27 al 29 de Octubre de 2023!
        </div>
    </div>;
}

export default WelcomeBanner;